import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21accc18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "salesCard" }
const _hoisted_2 = { class: "salesExtraWrap" }
const _hoisted_3 = { class: "salesExtra" }
const _hoisted_4 = { class: "salesBar" }
const _hoisted_5 = { class: "salesRank" }
const _hoisted_6 = { class: "rankingTitle" }
const _hoisted_7 = { class: "rankingList" }
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "rankingItemValue" }
const _hoisted_10 = { class: "salesBar" }
const _hoisted_11 = { class: "salesRank" }
const _hoisted_12 = { class: "rankingTitle" }
const _hoisted_13 = { class: "rankingList" }
const _hoisted_14 = ["title"]
const _hoisted_15 = { class: "rankingItemValue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_bar = _resolveComponent("bar")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    loading: _ctx.loading,
    bordered: false,
    "body-style": _ctx.loading ? {} : { padding: 0 }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_tabs, {
          size: "large",
          "tab-bar-style": { marginBottom: '24px' }
        }, {
          tabBarExtraContent: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", {
                  class: _normalizeClass(_ctx.isActive('today')),
                  onClick: _cache[0] || (_cache[0] = () => _ctx.selectDate('today'))
                }, "1", 2),
                _createElementVNode("a", {
                  class: _normalizeClass(_ctx.isActive('week')),
                  onClick: _cache[1] || (_cache[1] = () => _ctx.selectDate('week'))
                }, "2", 2),
                _createElementVNode("a", {
                  class: _normalizeClass(_ctx.isActive('month')),
                  onClick: _cache[2] || (_cache[2] = () => _ctx.selectDate('month'))
                }, "3", 2),
                _createElementVNode("a", {
                  class: _normalizeClass(_ctx.isActive('year')),
                  onClick: _cache[3] || (_cache[3] = () => _ctx.selectDate('year'))
                }, "4", 2)
              ]),
              _createVNode(_component_a_range_picker, {
                style: {"width":"256px"},
                value: _ctx.rangePickerValue,
                onChange: _ctx.handleRangePickerChange
              }, null, 8, ["value", "onChange"])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, {
              key: "sales",
              tab: "收款"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { type: "flex" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, {
                      xl: 16,
                      lg: 12,
                      md: 12,
                      sm: 24,
                      xs: 24
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_bar, {
                            height: 295,
                            title: _ctx.t('dashboard.analysis.sales-trend'),
                            data: _ctx.salesData
                          }, null, 8, ["title", "data"])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, {
                      xl: 8,
                      lg: 12,
                      md: 12,
                      sm: 24,
                      xs: 24
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.t('dashboard.analysis.sales-ranking')), 1),
                          _createElementVNode("ul", _hoisted_7, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.rankingListData, (item, i) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: item.title
                              }, [
                                _createElementVNode("span", {
                                  class: _normalizeClass(['rankingItemNumber', { active: i < 3 }])
                                }, _toDisplayString(i + 1), 3),
                                _createElementVNode("span", {
                                  class: "rankingItemTitle",
                                  title: item.title
                                }, _toDisplayString(item.title), 9, _hoisted_8),
                                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.numeral(item.total).format('0,0')), 1)
                              ]))
                            }), 128))
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, {
              key: "views",
              tab: "耗单"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { type: "flex" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, {
                      xl: 16,
                      lg: 12,
                      md: 12,
                      sm: 24,
                      xs: 24
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_bar, {
                            height: 292,
                            title: _ctx.t('dashboard.analysis.visits-trend'),
                            data: _ctx.salesData
                          }, null, 8, ["title", "data"])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, {
                      xl: 8,
                      lg: 12,
                      md: 12,
                      sm: 24,
                      xs: 24
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.t('dashboard.analysis.visits-ranking')), 1),
                          _createElementVNode("ul", _hoisted_13, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.rankingListData, (item, i) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: item.title
                              }, [
                                _createElementVNode("span", {
                                  class: _normalizeClass(['rankingItemNumber', { active: i < 3 }])
                                }, _toDisplayString(i + 1), 3),
                                _createElementVNode("span", {
                                  class: "rankingItemTitle",
                                  title: item.title
                                }, _toDisplayString(item.title), 9, _hoisted_14),
                                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.numeral(item.total).format('0,0')), 1)
                              ]))
                            }), 128))
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["loading", "body-style"]))
}