
import { defineComponent, reactive, watch, PropType, onMounted } from 'vue';
import { Bar } from './charts';
import { useI18n } from 'vue-i18n';
import numeral from 'numeral';
import { SalesDataItem } from '@/api/dashboard/analysis';

export interface RankingDataItem {
  title: string;
  total: number | string;
}

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Function as PropType<(type: any) => void>,
      default: () => {
        return undefined;
      },
    },
    selectDate: {
      type: Function as PropType<(type: any) => void>,
      default: () => {
        return undefined;
      },
    },
    handleRangePickerChange: {
      type: Function,
      default: () => {
        return undefined;
      },
    },
    rangePickerValue: {
      type: Array as PropType<RankingDataItem[]>,
      required: true,
    },
    salesData: {
      type: Array as PropType<SalesDataItem[]>,
      required: true,
    },
  },
  setup() {
    const { t, locale } = useI18n();
    const state = reactive({
      rankingListData: [] as RankingDataItem[],
    });

    const generatorRankingData = () => {
      state.rankingListData = [];
      for (let i = 0; i < 7; i += 1) {
        state.rankingListData.push({
          title: t('dashboard.analysis.test', { no: i }),
          total: 323234,
        });
      }
    };

    onMounted(() => {
      generatorRankingData();

      watch(
        () => locale.value,
        () => {
          generatorRankingData();
        },
      );
    });

    return {
      t,
      numeral,
      state,
    };
  },
  components: {
    Bar,
  },
});
